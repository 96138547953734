<template>
  <v-autocomplete
    :value="value"
    :items="validItems"
    :item-text="textColumn"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    :deletable-chips="deletableChips || false"
    :label="label || ''"
    filled
    chips
    multiple
    v-bind="$attrs"
    @input="onChange"
  >
    <template v-if="isHasSelectAll" v-slot:prepend-item>
      <v-list-item @click="toggleSelectAll">
        <v-list-item-action>
          <v-checkbox :input-value="isSelectAll" color="primary"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Select All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>
  </v-autocomplete>
</template>

<script>
/**
 * ==================================================================================
 * Select Building
 * ==================================================================================
 **/

import bus from '@/store/modules/bus'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { find, findIndex } from 'lodash'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    items: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    placeholder: {
      type: String,
      default: 'Select building',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'name',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },

    deletableChips: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    isHasSelectAll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fetching: false,
      buildings: [],
      isSelectAll: false,
    }
  },

  watch: {
    value(newValue) {
      this.isSelectAll = this.arraysEqual(
        this.validItems.map((item) => item.id),
        newValue
      )
    },
    buildings(newValue) {
      this.isSelectAll = this.arraysEqual(
        this.validItems.map((item) => item.id),
        this.value
      )
    },
  },

  computed: {
    ...mapGetters({
      buildingId: 'filter/buildingId',
    }),

    validItems() {
      let items = [
        ...this.items,
        ...(this.buildings && this.buildings.length ? this.buildings : []),
      ]

      items = items.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      )

      return !this.viaIndex
        ? items
        : items.map((item, i) => {
            return {
              index: i,
              ...item,
            }
          })
    },

    viaIndex() {
      return this.valueColumn.toLowerCase() === 'index'
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }

    bus.$on('buildingCreated', this.addBuilding)
    bus.$on('buildingUpdated', this.updateBuilding)
    bus.$on('buildingRemoved', this.removeBuilding)
  },

  methods: {
    ...mapActions({
      getBuildings: 'building/getBuildings',
    }),

    ...mapMutations({
      clearBuildings: 'building/clearBuildingList',
    }),

    async fetch() {
      if (this.fetching) return

      this.fetching = true
      this.clearBuildings()

      await this.getBuildings()
        .then((data) => {
          this.buildings = data
          this.$emit('populated', this.buildings)
        })
        .finally(() => {
          this.fetching = false
        })
    },

    onChange(value) {
      this.$emit('input', value)
    },

    addBuilding(building) {
      const exist = find(this.buildings, { id: building.id })
      if (!exist) {
        this.buildings.push(building)
      }
    },

    updateBuilding(building) {
      const index = findIndex(this.buildings, { id: building.id })
      if (index) {
        this.$set(this.buildings, index, building)
      }
    },

    removeBuilding(building) {
      const index = findIndex(this.buildings, { id: building.id })
      if (index) {
        this.buildings.splice(index)
      }
    },

    toggleSelectAll() {
      // eslint-disable-next-line no-unused-vars
      let value = []
      if (!this.isSelectAll) {
        value = this.validItems.map((item) => item[this.valueColumn])
      }

      this.$emit('input', value)
    },

    arraysEqual(buildingIds, itemIds) {
      if (buildingIds.length !== itemIds.length) return false

      const sortedArr1 = buildingIds.slice().sort()
      const sortedArr2 = itemIds.slice().sort()

      return sortedArr1.every((value, index) => value === sortedArr2[index])
    },
  },
}
</script>
